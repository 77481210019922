<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12 has-text-centered has-background-light">
          <div class="columns">
            <div class="col p-3">
              <figure
                class="image is-64x64 is-inline-block"
                v-if="dashBoardData.school.imageUrl"
              >
                <img :src="dashBoardData.school.imageUrl" alt="avatar" />
              </figure>
            </div>
            <div class="col">
              <p class="title is-5 pl-3 pt-3 mb-0">
                <router-link :to="`/school/${this.schoolId}/edit`">
                  {{ dashBoardData.school.name }}
                </router-link>
              </p>
              <p class="sub-title is-fs-7 pl-3 mb-0 mt-1 has-text-left">
                {{ dashBoardData.sessionTitle }}
              </p>
              <p class="sub-title is-fs-7 pl-3 mb-0 has-text-left">
                {{ daysRemaining(dashBoardData.remainingDays) }} remaining
              </p>
            </div>
          </div>
        </div>
        <div class="column is-3 pl-0">
          <admin-dashboard-panel
            second-icon="fas fa-user-graduate"
            icon-color="#bcbcbc"
            title="Students"
            first-heading="Female"
            :first-heading-value="dashBoardData.numberOfFemaleStudents"
            second-heading="Male"
            :second-heading-value="dashBoardData.numberOfMaleStudents"
            :path="`/school/${schoolId}/students_in_school`"
          />
        </div>
        <div class="column is-3">
          <admin-dashboard-panel
            second-icon="fas fa-chalkboard-teacher"
            icon-color="#bcbcbc"
            title="Educators"
            first-heading="Female"
            :first-heading-value="dashBoardData.numberOfFemaleTeachers"
            second-heading="Male"
            :second-heading-value="dashBoardData.numberOfMaleTeachers"
            :path="`/school/${schoolId}/educators`"
          />
        </div>
        <div class="column is-3">
          <admin-dashboard-panel
            second-icon="fas fa-user-friends"
            icon-color="#bcbcbc"
            title="Parents"
            first-heading="Registered"
            :first-heading-value="dashBoardData.registeredParents"
            second-heading="Unregistered"
            :second-heading-value="dashBoardData.unRegisteredParents"
            :path="`/school/${schoolId}/parents`"
          />
        </div>
        <div class="column is-3 pr-0">
          <admin-dashboard-panel
            second-icon="fas fa-user-cog"
            icon-color="#bcbcbc"
            title="Admin"
            first-heading="Open Days"
            :first-heading-value="dashBoardData.openDays"
            second-heading="SMS"
            :second-heading-value="dashBoardData.smsUnit"
            :path="`/school/${schoolId}/admins`"
          />
        </div>
        <div class="column is-6 pl-0">
          <div class="card">
            <div class="card-content">
              <p class="title is-6">ATES Progress</p>
              <b-progress
                :value="dashBoardData.completedAtesPercentage"
                show-value
                format="percent"
                type="is-info"
                size="is-medium"
              ></b-progress>
            </div>
          </div>
        </div>
        <div class="column is-6 pr-0">
          <div class="card">
            <div class="card-content">
              <p class="title is-6">ATE MARKS Progress</p>
              <b-progress
                :value="dashBoardData.completedAteMarksPercentage"
                show-value
                format="percent"
                type="is-info"
                size="is-medium"
              ></b-progress>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import AdminDashboardPanel from '@/components/AdminDashBoardPanel'

export default {
  name: 'AdminDashboard',
  data() {
    return {
      pageTitle: 'Dashboard',
      schoolId: null,
      dashBoardData: {
        school: {},
      },
    }
  },
  props: {
    user: Object,
  },
  components: {
    AdminDashboardPanel,
  },
  methods: {
    daysRemaining(value) {
      return parseInt(value) > 1 ? `${value} days` : `${value} day`
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$apollo.addSmartQuery('dashBoardData', {
      query: gql`
        query AdminDashboardQuery($id: Int!) {
          adminDashboard(id: $id) {
            school {
              id
              name
              imageUrl
            }
            numberOfFemaleStudents
            numberOfMaleStudents
            numberOfFemaleTeachers
            numberOfMaleTeachers
            registeredParents
            unRegisteredParents
            openDays
            smsUnit
            sessionTitle
            remainingDays
            completedAtesPercentage
            completedAteMarksPercentage
          }
        }
      `,
      variables() {
        return { id: this.user.id }
      },
      update: (data) => data.adminDashboard,
    })

    this.$apollo.queries.dashBoardData.refetch()
  },
}
</script>
