<template>
  <div class="columns is-multiline">
    <div class="column is-12 has-background-light">
      <p class="title is-4">{{ dashBoardData.name }}</p>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline">
        <div class="column is-12 is-flex is-justify-content-center">
          <figure class="image is-64x64">
            <img
              class="is-rounded"
              :src="user.avatar"
              alt="avatar"
              v-if="user.avatar"
            />
            <img
              class="is-rounded"
              src="@/assets/images/default_avatar.png"
              alt="avatar"
              v-else
            />
          </figure>
        </div>
        <div class="column is-12">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">ATES</p>
                <p class="title">
                  {{ dashBoardData.percentageOfStartedAtes }}%
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">ATE MARKS</p>
                <p class="title">
                  {{ dashBoardData.percentageOfMarkedAteMarks }}%
                </p>
              </div>
            </div>
          </nav>
        </div>
        <div class="column is-12">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">CLASSES</p>
                <p class="title">
                  {{ dashBoardData.schoolClasses.length }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">PERFORMANCE</p>
                <p class="title">0%</p>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline">
        <div class="column is-12 is-flex is-justify-content-center">
          <p class="heading">ATES</p>
        </div>
        <div class="column is-12 pt-0 is-flex is-justify-content-center">
          <div class="chart-container">
            <canvas id="ates" width="150" height="150"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline">
        <div class="column is-12 is-flex is-justify-content-center">
          <p class="heading">ATE MARKS</p>
        </div>
        <div class="column is-12 pt-0 is-flex is-justify-content-center">
          <div class="chart-container">
            <canvas id="ate-marks" width="150" height="150"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { Chart, PieController, ArcElement } from 'chart.js'

Chart.register(PieController, ArcElement)

export default {
  name: 'AdminDashboard',
  data() {
    return {
      pageTitle: 'Dashboard',
      schoolId: null,
      dashBoardData: {
        schoolClasses: '',
      },
    }
  },
  props: {
    user: Object,
  },
  watch: {
    dashBoardData(data) {
      const atesCtx = document.getElementById('ates')
      const atesPieChart = new Chart(atesCtx, {
        type: 'pie',
        data: {
          labels: Object.keys(data.atesChart),
          datasets: [
            {
              data: Object.values(data.atesChart),
              backgroundColor: ['rgb(128, 179, 255, 0.6)', '#2d66d2'],
              borderWidth: 1,
            },
          ],
        },
        options: {},
      })

      const ateMarksCtx = document.getElementById('ate-marks')
      const ateMarksPieChart = new Chart(ateMarksCtx, {
        type: 'pie',
        data: {
          labels: Object.keys(data.ateMarksChart),
          datasets: [
            {
              data: Object.values(data.ateMarksChart),
              backgroundColor: ['rgb(128, 179, 255, 0.6)', '#2d66d2'],
              borderWidth: 1,
            },
          ],
        },
        options: {},
      })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$apollo.addSmartQuery('dashBoardData', {
      query: gql`
        query EducatorDashboardQuery($id: Int!) {
          educatorDashboard(id: $id) {
            name
            schoolClasses {
              id
            }
            atesChart
            ateMarksChart
            percentageOfStartedAtes
            percentageOfMarkedAteMarks
          }
        }
      `,
      variables: {
        id: this.user.id,
      },
      update: (data) => data.educatorDashboard,
    })

    this.$apollo.queries.dashBoardData.refetch()
  },
}
</script>

<style lang="scss" scoped>
.chart-container {
  width: 230px;
  height: 230px;
}
</style>
