<template>
  <div class="columns is-multiline is-flex is-justify-content-center">
    <div
      class="column is-4 has-text-centered"
      v-for="student in this.students"
      :key="student.id"
    >
      <student-panel :student="student" :school-id="schoolId" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ParentDashboardStudentPanel from '../components/ParentDashboardStudentPanel'

export default {
  name: 'AdminDashboard',
  data() {
    return {
      pageTitle: 'Dashboard',
      schoolId: null,
      parent: {},
      students: {},
      dashBoardData: {
        school: {},
      },
    }
  },
  props: {
    user: Object,
  },
  watch: {
    parent(data) {
      this.students = data.students
    },
  },
  components: {
    'student-panel': ParentDashboardStudentPanel,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$apollo.addSmartQuery('parent', {
      query: gql`
        query ParentQuery($id: ID!) {
          parent(id: $id) {
            id
            country {
              name
            }
            state {
              name
            }
            stateLga {
              name
            }
            familyName
            nameOfFather
            dobOfFather
            phoneOfFather
            emailOfFather
            nameOfMother
            dobOfMother
            phoneOfMother
            emailOfMother
            houseAddress
            studentsCount
            students {
              id
              name
              schoolClass {
                id
                name
              }
              termId
              age
              gender
              ateMarksAverageScore
            }
            users {
              id
            }
          }
        }
      `,
      variables() {
        return { id: this.user.context }
      },
    })

    this.$apollo.queries.parent.refetch()
  },
}
</script>
