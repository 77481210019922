<template>
  <div class="card">
    <div class="card-image">
      <div class="has-text-centered">
        <figure class="image is-64x64 is-inline-block">
          <img
            class="is-rounded"
            :src="student.imageUrl"
            alt="avatar"
            v-if="student.imageUrl"
          />
          <img
            class="is-rounded mt-5"
            src="@/assets/images/default_avatar.png"
            alt="avatar"
            v-else
          />
        </figure>
      </div>
    </div>
    <!-- <hr /> -->
    <div class="card-content">
      <p class="title is-6 has-text-centered mt-5">{{ student.name }}</p>
      <div class="columns is-multiline">
        <div class="column is-12">
          <p>{{ student.schoolClass.name }}</p>
        </div>
        <div class="column is-12">
          <nav class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Total</p>
                <p class="title is-5">
                  {{ student.ateMarksAverageScore.total }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Average</p>
                <p class="title is-5">
                  {{ student.ateMarksAverageScore.average }}
                </p>
              </div>
            </div>
          </nav>
        </div>
        <div class="column is-12 pb-0">
          <router-link
            :to="`/school/${schoolId}/school_class/${student.schoolClass.id}/student_info/${student.id}`"
          >
            <p class="small-link">Student's Info</p>
          </router-link>
        </div>
        <div class="column is-12 pb-0">
          <router-link
            :to="`/school/${schoolId}/school_class/${student.schoolClass.id}/result_info/${student.id}/term_id/${student.termId}`"
          >
            <p class="small-link">Result Info</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {
        return {
          schoolClass: {},
        }
      },
    },
    schoolId: Number,
  },
}
</script>
