<template>
  <div>
    <admin-dashboard
      :user="user"
      v-if="user.role === 'admin' || user.role === 'host'"
    />
    <educator-dashboard :user="user" v-if="user.role === 'educator'" />
    <parent-dashboard :user="user" v-if="user.role === 'parent'" />
  </div>
</template>

<script>
import { fetchUser } from '@/assets/js/app_info'
import AdminDashboard from '@/components/AdminDashboard'
import EducatorDashboard from '@/components/EducatorDashboard'
import ParentDashboard from '@/components/ParentDashboard'

export default {
  name: 'Dashboard',
  data() {
    return {
      pageTitle: 'Dashboard',
      user: {},
      schoolId: null,
    }
  },
  components: {
    AdminDashboard,
    EducatorDashboard,
    ParentDashboard,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id

      if (user.role == 'host' || user.role == 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'School Classes',
            route: `/school/${this.schoolId}/school_classes`,
          },
          {
            name: 'Students',
            route: `/school/${this.schoolId}/students_in_school`,
          },
        ])
      } else if (user.role == 'educator') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Assigned Classes',
            route: `/school/${this.schoolId}/educator_assigned_classes`,
          },
          {
            name: 'Attendance',
            route: `/school/${this.schoolId}/attendances`,
          },
        ])
      } else if (user.role == 'student') {
        this.$store.commit('setSubMenus', [])
      } else if (user.role == 'parent') {
        this.$store.commit('setSubMenus', [])
      }
    })
  },
}
</script>
