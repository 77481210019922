<template>
  <div class="card">
    <div class="card-image">
      <router-link :to="path" class="mt-0">
        <div class="fa-3x has-text-centered">
          <span class="fa-layers fa-fw">
            <i :class="[firstIcon]" />
            <i :class="[secondIcon]" :style="{ color: iconColor }" />
          </span>
        </div>
      </router-link>
    </div>
    <div class="card-content">
      <p class="title is-6 has-text-centered">{{ title }}</p>
      <div class="columns">
        <div class="column">
          <div class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">{{ firstHeading }}</p>
                <p class="title is-6">{{ firstHeadingValue }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">{{ secondHeading }}</p>
                <p class="title is-6">{{ secondHeadingValue }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstIcon: String,
    secondIcon: String,
    iconColor: String,
    title: String,
    firstHeading: String,
    firstHeadingValue: String,
    secondHeading: String,
    secondHeadingValue: String,
    path: String,
  },
}
</script>
